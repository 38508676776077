<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div
          class="column is-narrow-desktop is-hidden-mobile is-hidden-touch"
        >
          <Sidebar />
        </div>
        <div class="column">
          <div>
            <div class="is-flex is-justify-content-space-between block">
              <div>
                <h1 class="title">Account - Add Note</h1>
                <h2 class="subtitle">
                  Add new note
                </h2>
              </div>
              <div class="field is-grouped">
                <p class="control">
                  <button @click="save()" class="button is-success">
                    Save Note
                  </button>
                </p>
              </div>
            </div>
            <div v-if="editor" class="card block">
              <div class="card-content">
                <form class="block" @submit.prevent>
                  <div class="field">
                    <label class="label">Title</label>
                    <p class="control">
                      <input
                        v-model.trim="title"
                        class="input"
                        type="text"
                        placeholder="Title"
                      />
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Description</label>
                    <div class="control">
                      <textarea
                        v-model="description"
                        class="textarea"
                        placeholder="Short description"
                      ></textarea>
                    </div>
                  </div>
                </form>
                <editor-floating-menu
                  :editor="editor"
                  v-slot="{ commands, isActive, menu }"
                >
                  <div
                    class="editor__floating-menu is-active"
                    :style="`top: ${menu.top}px`"
                  >
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="showImagePrompt(commands.image)"
                    >
                      <i class="fas fa-image"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <i class="fas fa-bold"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <i class="fas fa-italic"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.strike() }"
                      @click="commands.strike"
                    >
                      <i class="fas fa-strikethrough"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <i class="fas fa-underline"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.code() }"
                      @click="commands.code"
                    >
                      <i class="fas fa-code"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.paragraph() }"
                      @click="commands.paragraph"
                    >
                      <i class="fas fa-paragraph"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                      @click="commands.heading({ level: 1 })"
                    >
                      H1
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                      @click="commands.heading({ level: 2 })"
                    >
                      H2
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                      @click="commands.heading({ level: 3 })"
                    >
                      H3
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 4 }) }"
                      @click="commands.heading({ level: 4 })"
                    >
                      H4
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 5 }) }"
                      @click="commands.heading({ level: 5 })"
                    >
                      H5
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 6 }) }"
                      @click="commands.heading({ level: 6 })"
                    >
                      H6
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bullet_list() }"
                      @click="commands.bullet_list"
                    >
                      <i class="fas fa-list-ul"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.ordered_list() }"
                      @click="commands.ordered_list"
                    >
                      <i class="fas fa-list-ol"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.blockquote() }"
                      @click="commands.blockquote"
                    >
                      <i class="fas fa-quote-left"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.code_block() }"
                      @click="commands.code_block"
                    >
                      <i class="fas fa-terminal"></i>
                    </button>

                    <button
                      class="menubar__button"
                      @click="commands.horizontal_rule"
                    >
                      ----
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.todo_list() }"
                      @click="commands.todo_list"
                    >
                      <icon name="checklist" />
                    </button>

                    <button class="menubar__button" @click="commands.undo">
                      <i class="fas fa-undo"></i>
                    </button>

                    <button class="menubar__button" @click="commands.redo">
                      <i class="fas fa-redo"></i>
                    </button>
                  </div>
                </editor-floating-menu>
                <editor-content :editor="editor" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  Editor,
  EditorContent,
  EditorMenuBar,
  EditorFloatingMenu,
} from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from "tiptap-extensions";
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import Icon from "@/components/Icon";
import { auth, db } from "@/firebase";

export default {
  name: "AccountMyNotesAddNote",
  components: {
    Sidebar,
    EditorContent,
    EditorMenuBar,
    EditorFloatingMenu,
    Icon,
    Mobilemenu,
  },
  data() {
    return {
      title: null,
      description: null,
      editor: new Editor({
        editable: true,
        extensions: [
          new Image(),
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3, 4, 5, 6] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem({
            nested: true,
          }),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          // new Table({
          //   resizable: true,
          // }),
        ],
        content: `
          <h2>
            Hi there, the above field is just for description. Your notes should be made here.
          </h2>
          <p>
            this is a very <em>basic</em> example of a note.
          </p>
          <pre><code>body { display: none; }</code></pre>
          <ul>
            <li>
              A regular list
            </li>
            <li>
              With regular items
            </li>
          </ul>
          <blockquote>
            It's amazing 👏
            <br />
            – mom
          </blockquote>
        `,
      }),
    };
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    async save() {
      const content = this.editor.getHTML();
      const result = await db
        .collection("users")
        .doc(this.$store.state.userProfile.uid)
        .collection("notes")
        .add({
          title: this.title,
          description: this.description,
          content: content,
          timestamp: new Date(),
          user: this.$store.state.userProfile.uid,
        });
      await this.$router.push({
        name: "AccountMyNotesNote",
        params: { note: result.id },
      });
    },
    showImagePrompt(command) {
      const src = prompt("Paste the URL of your image here");
      if (src !== null) {
        command({ src });
      }
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/variables";

.ProseMirror {
  &:focus {
    outline: none;
  }
  ul {
    list-style-type: disc;
    padding-left: 36px;
  }
  h2 {
    color: var(--text-primary-color);
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h2 {
    color: var(--text-primary-color);
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h3 {
    color: var(--text-primary-color);
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h4 {
    color: var(--text-primary-color);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h5 {
    color: var(--text-primary-color);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h6 {
    color: var(--text-primary-color);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125;
  }

  pre {
    padding: 0.7rem 1rem;
    border-radius: 5px;
    background: $color-black;
    color: $color-white;
    font-size: 0.8rem;
    overflow-x: auto;

    code {
      display: block;
    }
  }

  p code {
    padding: 0.2rem 0.4rem;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    background: rgba($color-black, 0.1);
    color: rgba($color-black, 0.8);
  }

  ul,
  ol {
    padding-left: 2rem;
  }

  li > p,
  li > ol,
  li > ul {
    margin: 0;
  }

  a {
    color: inherit;
  }

  blockquote {
    border-left: 3px solid rgba($color-black, 0.1);
    color: rgba($color-black, 0.8);
    padding-left: 0.8rem;
    font-style: italic;

    p {
      margin: 0;
    }
  }

  img {
    max-width: 100%;
    border-radius: 3px;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid $color-grey;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: 0;
      width: 4px;
      z-index: 20;
      background-color: #adf;
      pointer-events: none;
    }
  }

  .tableWrapper {
    margin: 1em 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
  blockquote,
  h1,
  h2,
  h3,
  ol,
  p,
  pre,
  ul {
    margin: 1rem 0;
  }

  ul[data-type="todo_list"] {
    padding-left: 0;
  }
  li[data-type="todo_item"] {
    display: flex;
    flex-direction: row;
  }
  .todo-checkbox {
    border: 2px solid $color-black;
    height: 0.9em;
    width: 0.9em;
    box-sizing: border-box;
    margin-right: 10px;
    margin-top: 0.3rem;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    border-radius: 0.2em;
    background-color: transparent;
    transition: 0.4s background;
  }
  .todo-content {
    flex: 1;
    > p:last-of-type {
      margin-bottom: 0;
      margin-top: 0;
    }
    > ul[data-type="todo_list"] {
      margin: 0.5rem 0;
    }
  }
  li[data-done="true"] {
    > .todo-content {
      > p {
        text-decoration: line-through;
      }
    }
    > .todo-checkbox {
      background-color: $color-black;
    }
  }
  li[data-done="false"] {
    text-decoration: none;
  }
}
.editor {
  position: relative;
  &__floating-menu {
    position: absolute;
    z-index: 1;
    margin-top: -0.25rem;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    &.is-active {
      opacity: 1;
      visibility: visible;
      background: lavenderblush;
      position: fixed;
      top: 0;
      padding: 10px;
    }
  }
}
</style>
